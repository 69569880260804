
import errors from '@toznysecure/sdk/types/errors'

export function parseFragment(fragment) {
  fragment = fragment.replace(/#/, '')
  let parsedParams = {}
  let params = fragment.split('&')
  params.forEach(param => {
    const parts = param.split('=')
    parsedParams[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
  })
  return parsedParams
}

export function identityCacheKey(realmName) {
  return `${realmName}:identity`
}

export function setCache(cacheKey, value) {
  localStorage.setItem(cacheKey, JSON.stringify(value))
}

export function loadCache(cacheKey) {
  const cacheJSON = localStorage.getItem(cacheKey)
  if (cacheJSON == '') {
    return null
  }
  try {
    return JSON.parse(cacheJSON)
  } catch (_) {
    return null
  }
}

export function clearCache(cacheKey) {
  localStorage.removeItem(cacheKey)
}

export function validatePassword(password) {
  if (password.length < 10) {
    return 'Password must be at least 10 characters.'
  }
  return ''
}

export const missingIdentityError = new Error('Identity is not set')

export function invalidCredentialsError(message, response) {
  return new errors.identity.InvalidCredentials(response, message)
}

export function isUnauthorized(error) {
  return error === missingIdentityError
    || error instanceof errors.identity.InvalidCredentials
    || (error instanceof errors.general.RequestError && error.response.status === 401)
}

export function rootIdentity(rootState) {
  const identity = rootState.identity
  if (typeof identity !== 'object') {
    throw missingIdentityError
  }
  return identity
}

export function checkStatus(response, message) {
  if(response.status === 401) {
    throw invalidCredentialsError('Account request was not authorized', response)
  } else if (! response.ok) {
    const errorMessage = message || response.statusText
    throw new Error(errorMessage)
  }
}
