import Vue from 'vue'
import Vuex from 'vuex'
import Tozny from '@toznysecure/sdk/browser/sodium'
import sessions from './modules/sessions'
import applications from './modules/applications'
import mfa from './modules/mfa'
import secrets from './modules/secrets'
import nav from './modules/nav'
import tozny from '../api/tozny'
import {
  setCache,
  loadCache,
  clearCache,
  identityCacheKey,
  isUnauthorized
} from '@/utils/utils'


Vue.use(Vuex)

/* Cache for progressive action promise */
let continuation = null

/* Set up modules */
const modules = {
  sessions,
  applications,
  mfa,
  secrets,
  nav,
}

/** Network address of the Tozny API to use for making requests */
const TOZNY_API_HOST = global.API_URL
const TOZID_HOSTNAME = global.TOZID_HOSTNAME
const state = {
  globalError: '',
  loginError: '',
  loggingIn: false,
  loginAction: '',
  loginFields: {},
  loginContext: {},
  realmName: '',
  identity: false,
  mfaOptions: [],
}
const getters = {
  realm(state) {
    if (state.realmName === '') {
      return null
    }
    state.realmName = state.realmName.toLowerCase()
    return new Tozny.identity.Realm(
      state.realmName,
      'account',
      `${TOZID_HOSTNAME}/${state.realmName}/recover`,
      TOZNY_API_HOST
    )
  },
  apiUrlRoot(state) {
    return `${TOZNY_API_HOST}/auth/realms/${state.realmName}/account-api`
  },
}

const mutations = {
  SET_REALM_NAME(state, realmName) {
    state.realmName = realmName
  },
  SET_MFA_OPTIONS(state, mfa) {
    state.mfaOptions = mfa
  },
  SET_IDENTITY(state, identity) {
    state.identity = identity
  },
  SET_LOGIN_ACTION(state, { type, fields, context }) {
    state.loginAction = type
    state.loginFields = fields
    state.loginContext = context
  },
  SET_LOGIN_ERROR(state, message) {
    state.loginError = message
  },
  SET_LOGGING_IN(state, active) {
    state.loggingIn = active
  },
  CLEAR_IDENTITY(state) {
    state.identity = false
  },
  CLEAR_LOGIN_ACTION(state) {
    state.loginAction = ''
    state.loginFields = {}
    state.loginContext = {}
  },
  CLEAR_LOGIN_ERROR(state) {
    state.loginError = ''
  },
}

const actions = {
  async bootstrap({ commit, dispatch, state, getters }, realmName) {
    if (realmName === state.realmName) {
      return
    }
    dispatch('reset')
    commit('SET_REALM_NAME', realmName)
    if (!realmName) {
      return
    }
    const now = Date.now()
    let identityCache = loadCache(identityCacheKey(realmName))
    if (identityCache) {
      const realm = getters.realm
      const identity = realm.fromObject(identityCache)
      const info = await identity.agentInfo()
      if (now > info.expiry) {
        dispatch('logout')
        return
      }
      commit('SET_IDENTITY', identity)
      dispatch('fetchRealmSettings')
    }
  },
  async login({ state, getters, commit }, { username, password }) {
    try {
      commit('CLEAR_LOGIN_ERROR')
      commit('CLEAR_LOGIN_ACTION')
      commit('SET_LOGGING_IN', true)
      const realm = getters.realm
      if (!realm) {
        throw new Error('Realm is not set')
      }

      const identity = await realm.login(username, password, (err, data) => {
        return new Promise(resolve => {
          commit('SET_LOGIN_ACTION', data)
          if (err !== null) {
            commit('SET_LOGIN_ERROR', err.message)
          }
          commit('SET_LOGGING_IN', false)
          continuation = resolve
        })
      })
      setCache(identityCacheKey(state.realmName), identity.serialize())
      commit('SET_IDENTITY', identity)
      commit('CLEAR_LOGIN_ACTION')
    } catch (e) {
      commit('SET_LOGGING_IN', false)
      commit('CLEAR_LOGIN_ACTION')
      let message
      switch (e.name) {
        case 'InvalidCredentials':
          message =
            'Your credentials do not match our records. Please try again.'
          break
        case 'CredentialNoteError':
          if (e.response.status === 401) {
            message =
              'An authorization error occurred while trying to load your ' +
              'identity. This could mean your identity information is out ' +
              'of sync. You could try resetting your password. If the ' +
              'problem persists, please contact your administrator.'
          }
          message =
            'We were unable to load your identity. This is likely a ' +
            'problem on our end. Please try again later.'
          break
        case 'CredentialDataError':
          message =
            'There appears to be an issue with your identity data. You ' +
            'could try resetting your password. If this problem persists, ' +
            'please contact your administrator.'
          break
        default:
          message =
            `General service error: ${e.message}. This is likely a problem ` +
            'on our end. If the problem persists, please contact your ' +
            'administrator.'
      }
      commit('SET_LOGIN_ERROR', message)
    }
  },

  async doLoginAction({ state, commit }, data) {
    if (continuation !== null && state.loginAction) {
      commit('SET_LOGGING_IN', true)
      continuation(data)
    }
  },

  async checkPushComplete({ commit, dispatch }, id) {
    const request = await fetch(
      `${TOZNY_API_HOST}/v1/identity/challenge/${id}`,
      {
        method: 'GET',
      }
    )
    if (request.ok) {
      return true
    }
    let err
    switch (request.status) {
      case 401:
        err = 'The push challenge was rejected'
        break
      case 404:
        err = 'The push challenge was not found'
        break
      case 428:
        return false
      default:
        err = 'An unknown error occurred when checking push status'
        break
    }
    await dispatch('logout')
    commit('SET_LOGIN_ERROR', err)
  },

  async ssoLogin({ commit, state }, { actionURL }) {
    if (!state.identity) {
      return false
    }
    commit('SET_LOGGING_IN', true)
    const token = await state.identity.agentToken()
    const req = await fetch(actionURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!req.ok) {
      commit('SET_LOGGING_IN', false)
      commit('SET_LOGIN_ERROR', `Unable to complete login: ${req.statusText}`)
      return false
    }
    const challenge = await req.json()
    let nextAction = challenge.action
    const signedChallenge = await Tozny.crypto.sign(
      challenge.challenge,
      state.identity.storage.config.privateSigningKey
    )

    nextAction += '&challenge=' + signedChallenge
    commit('SET_LOGGING_IN', false)
    window.location.replace(nextAction)
    return true
  },
  async logout({ commit, dispatch, state }, waitForLogout = false) {
    if (!state.identity) {
      return
    }
    const logoutFailHandler = success => {
      if (!success) {
        commit(
          'SET_LOGIN_ERROR',
          'You have been logged out, but the servers was unable to invalidate ' +
          'your session. You might try logging in again and removing the ' +
          'session in the sessions tab.'
        )
      }
    }
    // wait for the logout completion if asked, otherwise, fire and forget
    if (waitForLogout) {
      const success = await state.identity.logout()
      logoutFailHandler(success)
    } else {
      // fire now while identity is present, but don't await the result before
      // clearing state. If it fails we'll report the login error.
      state.identity.logout().then(logoutFailHandler)
    }
    await clearCache(identityCacheKey(state.realmName))
    await dispatch('reset')
  },
  async forceLogout({ commit, dispatch, state }) {
    await dispatch('logout')
    if (state.loginError === '') {
      commit(
        'SET_LOGIN_ERROR',
        'Your session has expired, please log in again.'
      )
    }
  },
  async frontendLogout({getters, dispatch, state}, params) {
    const realm = getters.realm
    if (!realm) {
      throw new Error('no realm available, unknown state.')
    }
    let redirect
    if (params.post_logout_redirect_uri) {
      redirect = realm.logoutRedirectUrl(params)
    }
    if (state.identity) {
      try {
        await dispatch('logout', true)
      } catch(e) {
        // if logout failed, report this is done, which will display the error
        return true
      }
    }
    if (redirect) {
      window.location = redirect
      // We are redirecting, report we are not done yet.
      return false
    }
    // We are done, report it and allow continued processing.
    return true
  },
  reset({ commit, dispatch }) {
    commit('CLEAR_IDENTITY')
    commit('CLEAR_LOGIN_ACTION')
    commit('CLEAR_LOGIN_ERROR')
    commit('SET_LOGGING_IN', false)
    dispatch('sessions/reset')
    dispatch('applications/reset')
    dispatch('mfa/reset')
  },
  async fetchRealmSettings({ commit, dispatch, rootState }) {
    try {
      const identity = rootState.identity
      let realmPrivateInfo = await tozny.realmPrivateInfo(identity)
      commit('SET_MFA_OPTIONS', realmPrivateInfo.mfa_available)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
}

export default new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
})
