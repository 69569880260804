import tozny from '../../api/tozny'
/**
 * nav implements state for nav menu
 * @module nav
 */

const state = {
  tozIDSecretsEnabled: false, // If this is true the secret icon will show up, if false it will not show up
}

/** synchronous mutations of nav state */
const mutations = {
  TOGGLE_SECRETS_ENABLED(state) {
    state.tozIDSecretsEnabled = !state.tozIDSecretsEnabled
  },
  SET_SECRETS_ENABLED(state, enabled) {
    state.tozIDSecretsEnabled = enabled
  },
}

/** synchronous actions */
const actions = {
  async toggleTozIDSecretOpen({ commit, rootState }) {
    // get endpoint to grab realm settings
    const identity = rootState.identity
    let realmPrivateInfo = await tozny.realmPrivateInfo(identity)
    commit('SET_SECRETS_ENABLED', realmPrivateInfo.secrets_enabled)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
