/**
 * State associated with active mfa.
 */

/**
 * state and actions surrounding identity mfa
 * @module mfa
 */

import qrCode from 'qrcode-generator'
import Tozny from '@toznysecure/sdk/browser'
import {
  isUnauthorized,
  rootIdentity,
  checkStatus
} from '../../utils/utils'
import { credentialedDecodeResponse } from '@toznysecure/sdk/lib/utils'

const TOZNY_API_HOST = global.API_URL

/** initial session state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  type: 'none',
  secret: '',
  qrCode: '',
  deviceRegistration: '',
  pushQRReady: false,
  policy: {},
  errorMessage: '',
}
/** cache-able getters for the current mfa for the active identity */
const getters = {
  hasError: state => {
    return !!state.errorMessage
  },
  isIdle: state => {
    return state.status.substring(0, 4) === 'idle'
  },
  pushQRReady: state => {
    return state.pushQRReady
  },
  qrCodeImg: state => {
    const qr = qrCode(0, 'L')
    qr.addData(state.qrCode)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },
  pushQRCodeImg: state => {
    const qr = qrCode(0, 'L')
    qr.addData(state.deviceRegistration)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_DEVICE_REGISTRATION(state, reg) {
    state.deviceRegistration = reg
    state.pushQRReady = true
  },
  CLEAR_DEVICE_REGISTRATION(state) {
    state.deviceRegistration = ''
    state.pushQRReady = false
  },
  SET_MFA(state, mfa) {
    state.type = mfa.type
    // If type is set, MFA is configured, do not take in secrets, etc.
    if (state.type !== 'none') {
      state.secret = ''
      state.qrCode = ''
      state.policy = {}
      return
    }
    if (mfa.secret) {
      state.secret = mfa.secret
    }
    if (mfa.qrCode) {
      state.qrCode = mfa.qrCode
    }
    if (mfa.policy) {
      state.policy = mfa.policy
    }
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_MFA(state) {
    state.type = 'none'
    state.secret = ''
    state.qrCode = ''
    state.policy = {}
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
}

const statuses = ['initializing', 'loading', 'idle', 'idle.submitting', 'error']

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  /**
   * Syncs the state machine with the mfa state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.type !== 'none' || state.secret !== '') {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadMfa')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async checkMFASetup({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(`${rootGetters['apiUrlRoot']}/mfa`, {
        headers: {
          Accept: 'application/json',
        },
      })
      checkStatus(request, 'Failed to fetch multi-factor authentication data.')
      const mfa = await request.json()
      if (mfa.type === 'none') {
        return false
      }
      commit('SET_MFA', mfa)
      return true
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
        return true
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
        return true
      }
    }
  },
  async loadMfa({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(`${rootGetters['apiUrlRoot']}/mfa`, {
        headers: {
          Accept: 'application/json',
        },
      })
      checkStatus(request)
      const mfa = await request.json()
      commit('SET_MFA', mfa)
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async setupTotp({ commit, dispatch, state, rootState, rootGetters }, totp) {
    commit('CLEAR_ERROR')
    await dispatch('transitionStatus', 'idle.submitting')
    try {
      const identity = rootIdentity(rootState)
      if (totp === '') {
        throw new Error('You must enter a one-time code')
      }
      if (totp.length !== state.policy.digits) {
        throw new Error(
          `One-time code should be ${state.policy.digits} digits long`
        )
      }
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/totp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            secret: state.secret,
            totp,
          }),
        }
      )
      checkStatus(request, `Unable to complete setup: ${request.statusText}`)
      commit('SET_MFA', { type: 'totp' })
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'idle' })
      }
    }
  },
  async removeMFA({ commit, dispatch, rootState, rootGetters }) {
    await dispatch('transitionStatus', 'loading')
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/remove`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
        }
      )
      checkStatus(request, `Unable to complete removal: ${request.statusText}`)
      commit('CLEAR_DEVICE_REGISTRATION')
      throw new Error('Successfully removed your MFA!')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async setupMobilePush({ commit, dispatch, rootState }) {
    try {
      const identity = rootIdentity(rootState)
      let signingKeys = await Tozny.crypto.generateSigningKeypair()
      let encryptionKeys = await Tozny.crypto.generateKeypair()
      let requestBody = JSON.stringify({
        temporary_public_key: signingKeys.publicKey,
        temporary_public_encryption_key: encryptionKeys.publicKey,
      })
      const request = await identity.storage.authenticator.tsv1Fetch(
        `${TOZNY_API_HOST}/v1/identity/register/device`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
          },
          body: requestBody,
        }
      )
      const registrationResponse = await credentialedDecodeResponse(request)
      let deviceRegistrationInfo = {
        action: 'register',
        data: {
          registration_id: registrationResponse.registration_id,
          api_host: TOZNY_API_HOST,
          temporary_private_key: signingKeys.privateKey,
          temporary_private_encryption_key: encryptionKeys.privateKey,
          temporary_public_encryption_key: encryptionKeys.publicKey,
          realm: identity.config.realmName,
          username: identity.config.username,
          totp_secret: registrationResponse.encrypted_totp_secret,
        },
      }
      let displayDeviceRegistrationInfo = JSON.stringify(deviceRegistrationInfo)
      commit('SET_DEVICE_REGISTRATION', displayDeviceRegistrationInfo)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, {root: true})
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR_MFA')
    commit('CLEAR_ERROR')
  },
  async reload({ dispatch }) {
    await dispatch('reset')
    await dispatch('initialize')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'mfa' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
