<template>
  <main class="min-h-full bg-gray-lightest pt-20 login-layout">
    <div class="max-w-lg md:w-10/12 mx-auto">
      <ContentColumn>
        <div id="pre-login-form"></div>
        <slot></slot>
        <div id="post-login-form"></div>
      </ContentColumn>
    </div>
  </main>
</template>

<script>
import ContentColumn from '@/Common/ContentColumn'
export default {
  name: 'AuthenticationLayout',
  components: {
    ContentColumn,
  },
  beforeMount() {
    document.body.className = document.body.className.replace( /(?:^|\s)authenticated(?!\S)/ , '' )
  }
}
</script>
